import React from "react";

const PressItem = ({
  title,
  description,
  authorURL,
  author,
  date,
  url,
  imageAlt,
  imageSrc,
}) => {
  return (
    <article className="flex items-center py-4 border-b border-gray-200">
      <div>
        <header>
          <h2 className="mb-2 h4">
            <a
              href={url}
              className="hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
          </h2>
        </header>
        <div className="mb-4 text-lg text-gray-600">{description}</div>
        <footer className="text-sm">
          <div className="flex items-center">
            <div className="flex flex-shrink-0 mr-3">
              <div className="relative" href="#0">
                <span className="absolute inset-0 -m-px" aria-hidden="true">
                  <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                </span>
                <img
                  className="relative rounded-full"
                  src={imageSrc}
                  alt={imageAlt || "img"}
                  width={32}
                  loading="lazy"
                  height={32}
                />
              </div>
            </div>
            <div>
              <span className="text-gray-600">By </span>
              <a
                className="font-medium hover:underline"
                href={authorURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {author}
              </a>
              <span className="text-gray-600"> · {date}</span>
            </div>
          </div>
        </footer>
      </div>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex-shrink-0 block ml-6"
      >
        <span className="sr-only">Read more</span>
        <svg
          className="w-4 h-4 text-blue-600 fill-current"
          viewBox="0 0 16 16"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
    </article>
  );
};

const PressList = () => {
  return (
    <section>
      <div className="max-w-6xl px-4 mx-auto sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Page header */}
          <div className="relative max-w-3xl pb-12 text-center md:pb-20 md:text-left">
            <h1 className="mb-4 h1">Encord in the news</h1>
            <p className="text-xl text-gray-600">
              Take a look at what the press has been saying about Encord.
            </p>
          </div>

          {/* Main content */}
          <div className="md:flex md:justify-between">
            {/* Articles container */}

            <div className="-mt-4 md:flex-grow">

            <PressItem
                title="Encord lands new cash to grow its data dev tools for AI"
                description="Labeling and annotation platforms might not get the attention flashy new generative AI models do. But they’re essential. The data on which many models train must be labeled, or the models wouldn’t be able to interpret that data during the training process."
                date="August 13, 2024"
                url="https://techcrunch.com/2024/08/13/encord-lands-new-cash-to-grow-its-data-labeling-tools-for-ai/"
                author="Kyle Wiggers"
                imageAlt="techcrunch Logo"
                imageSrc="https://images.prismic.io/encord/0582dfeb-afeb-44ce-8d2b-9e422d619500_tc_logo.jpeg?auto=compress,format"
                authorURL="https://techcrunch.com/author/kyle-wiggers/"
              />

              <PressItem
                title="Comment: Google Launches Tool That Detects AI Images"
                description="Ulrik, Co-Founder & President at Encord, shares his thoughts on synthetic data, and Deepmind’s SynthID, with Forbes."
                date="August 31, 2023"
                url="https://www.forbes.com/sites/jenniferhicks/2023/08/31/google-launches-tool-that-detects-ai-images-in-effort-to-curb-deepfakes/?sh=3b6912265ee5"
                author="Jennifer Kite-Powell"
                imageAlt="Forbes Logo"
                imageSrc="https://encord.cdn.prismic.io/encord/bcca3a02-0d79-4c23-a390-1f55fdbfc8fc_forbes-logo.svg"
                authorURL="https://www.forbes.com/sites/jenniferkitepowell/?sh=577cb08249fel"
              />

              <PressItem
                title="Artificial intelligence will ‘increase the standard’ of sport - experts from Encord explain"
                description="Technology such as Hawk-Eye and VAR is already a key part of some sports."
                date="August 9, 2023"
                url="https://www.independent.co.uk/sport/technology-var-wimbledon-tennis-b2390016.html/"
                author="Jonathan Veal"
                imageAlt="Independent Logo"
                imageSrc="https://encord.cdn.prismic.io/encord/2cb601bb-fe22-450d-8b54-2d08c8bab366_independent-logo.svg"
                authorURL="https://www.independent.co.uk/author/jonathan-veal"
              />

              <PressItem
                title="Comment: Apple added $71 billion to its market value after news that it’s been secretly building an ‘Apple GPT’ to rival OpenAI"
                description="Ulrik, Co-Founder & President at Encord, shares his thoughts on Apple's new LLM with Fortune."
                date="July 20, 2023"
                url="https://fortune.com/2023/07/20/apple-applegpt-openai-trillion-dollar-market-cap-generative-ai/"
                author="Chloe Taylor"
                imageAlt="Fortune Logo"
                imageSrc="https://encord.cdn.prismic.io/encord/e03d6fea-c252-465a-8f75-42df3a122043_press-fortune-logo.svg"
                authorURL="https://fortune.com/author/chloe-taylor/"
              />


              <PressItem
                title="Why we can’t open-source a solution to A.I.’s ethical issues"
                description="Frederik, Lead ML Engineer at Encord, shares his thoughts on the ethical implications open-source AI development with Fortune."
                date="June 16, 2023"
                url="https://fortune.com/2023/06/16/open-source-a-solution-ai-ethical-issues-tech/"
                author="Frederik Hvilshøj"
                imageAlt="Fortune Logo"
                imageSrc="https://encord.cdn.prismic.io/encord/e03d6fea-c252-465a-8f75-42df3a122043_press-fortune-logo.svg"
                authorURL="https://encord.com/author/frederik-hvilsh%C3%B8j/"
              />




              <PressItem
                imageSrc="https://encord.cdn.prismic.io/encord/d2d6a8af-4dab-4aa1-859d-b8027eab8124_svgexport-2.svg"
                title="Foundation models: Building a future with less human input"
                description="The field of machine learning is constantly evolving and the emergence of foundation models is transforming the way we approach the development and deployment of machine learning models."
                date="June 8, 2023"
                url="https://www.maddyness.com/uk/2023/06/08/foundation-models-building-a-future-with-less-human-input/"
                author="Ulrik Stig Hansen"
                imageAlt="Maddyness Logo"
                authorURL="https://encord.com/author/ulrik-stig-hansen/"
              />

              <PressItem
                imageSrc="https://encord.cdn.prismic.io/encord/ce4e087b-1f28-4d60-b785-a75f7b87338c_telegraph-logo-icon.svg"
                title="The dystopian future of menial work as AI replaces humans"
                description="Dr Andreas Heindl, ML Product Manager at Encord, shares his thoughts on the future of data labeling with The Telegraph."
                date="May 15, 2023"
                url="https://www.telegraph.co.uk/business/2023/05/15/dystopian-future-of-menial-work-ai-replaces-humans/"
                author="Matthew Field"
                imageAlt="The Telegraph Logo"
                authorURL="https://www.telegraph.co.uk/authors/m/ma-me/matthew-field"
              />

              <PressItem
                imageSrc="https://encord.cdn.prismic.io/encord/bcca3a02-0d79-4c23-a390-1f55fdbfc8fc_forbes-logo.svg"
                title="See How This AI Prototype Can Detect Asian Hornets And Protect Bees"
                description="Using artificial intelligence (AI) and computer vision and partnering with Encord, Pollenize is developing a prototype device that will be able to detect Asian Hornets and save the UK's honey bee population."
                date="May 14, 2023"
                url="https://www.forbes.com/sites/jenniferhicks/2023/05/14/see-how-this-ai-prototype-can-detect-asian-hornets-and-protect-bees"
                author="Jennifer Hicks"
                imageAlt="Forbes Logo"
                authorURL="https://www.forbes.com/sites/jenniferkitepowell/"
              />

              <PressItem
                imageSrc={
                  "https://encord.cdn.prismic.io/encord/ad7a4068-5e03-4db0-a8cc-2849d34a9a88_image+%2810%29.svg"
                }
                title="We must perfect predictive models for generative AI to deliver on the AI revolution"
                description="Ulrik Stig Hansen, Encord President & Co-Founder, shares his thoughts on predictive vs generative AI with VentureBeat."
                date="April 30, 2023"
                url="https://venturebeat.com/ai/we-must-perfect-predictive-models-for-generative-ai-to-deliver-on-the-ai-revolution/"
                author="Ulrik Stig Hansen"
                imageAlt="VentureBeat Logo"
                authorURL="https://encord.com/author/ulrik-stig-hansen/"
              />

              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/6a56bd35-9189-44c7-ad9b-f832118d479f_computer_world_logo.jpeg?auto=compress,format"
                }
                title="OpenAI unveils GPT-4, a new foundation for ChatGPT"
                description="Ulrik Stig Hansen, Encord Co-Founder & President, shares his thoughts on GPT-4 with Computerworld."
                date="March 15, 2023"
                url="https://www.techtarget.com/searchitoperations/feature/Meeting-the-challenges-of-scaling-AI-with-MLOps"
                author="Lucas Mearian"
                imageAlt="ComputerWorld Logo"
                authorURL="https://www.techtarget.com/contributor/Lev-Craig"
              />


              <PressItem
                imageSrc={
                  "https://encord.cdn.prismic.io/encord/093465df-88f4-44b2-a79b-a9d7a6dca436_techtarget_logo.svg"
                }
                title="Meeting the challenges of scaling AI with MLOps"
                description="Eric Landau, Encord Co-Founder & CEO, shares his lessons at AI at Scale 2023. Specifically, the hard lesson that many companies learn between getting their models from a proof-of-concept state to the real world is that there's this additional set of requirements that come after you've already developed the first model."
                date="March 14, 2023"
                url="https://www.techtarget.com/searchitoperations/feature/Meeting-the-challenges-of-scaling-AI-with-MLOps"
                author="Lev Craig"
                imageAlt="TechTarget Logo"
                authorURL="https://www.techtarget.com/contributor/Lev-Craig"
              />

              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/264a0ff0-667c-44a3-ad93-05f3f1b0ed03_bn_logo.png?auto=compress,format"
                }
                title="The Rise of Edge ML"
                description="As the AI revolution accelerates and the number of use cases for machine learning continues to grow, more and more machine learning teams are pursuing edge ML. Encord's ML Lead, Frederik, shares his thoughts."
                date="March 13, 2023"
                url="https://aijourn.com/the-rise-of-edge-ml/"
                author="Frederik Hvilshøj"
                imageAlt="AI Journal Logo"
                authorURL="https://encord.com/author/frederik-hvilsh%C3%B8j/"
              />


              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/b7ac2f7c-3de6-461f-954a-f97f8b4758ac_healthcare-digital-dark-logo.png?auto=compress,format"
                }
                title="Encord’s Andreas Heindl & Eric Landau on AI saving lives"
                description="Mundane tasks & inefficient data sourcing are sucking time from healthcare professionals. Encord’s AI is here to change that with democratised healthcare."
                date="March 7, 2023"
                url="https://healthcare-digital.com/technology-and-ai/encords-andreas-heindl-eric-landau-on-ai-saving-lives"
                author="Helen Sydney Adams"
                imageAlt="Healthcare Digital Logo"
                authorURL="https://healthcare-digital.com/author/helen-sydney-adams"
              />

              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/f2b8e63d-320d-446b-b8e0-55a42db6eeda_techcrunch-icon.webp"
                }
                title="Active learning is the future of generative AI: Here’s how to leverage it"
                description="Eric Landau shares his thoughts on the difficulty on closing the AI prototype to production gap, and how it can be closed with active learning."
                date="February 28, 2023"
                url="https://techcrunch.com/2023/02/28/active-learning-is-the-future-of-generative-ai-heres-how-to-leverage-it/"
                author="Eric Landau"
                imageAlt="TechCrunch Logo"
                authorURL="https://www.linkedin.com/in/eric-landau-40992ab0/"
              />

              <PressItem
                imageSrc={
                  "https://encord.cdn.prismic.io/encord/ad7a4068-5e03-4db0-a8cc-2849d34a9a88_image+%2810%29.svg"
                }
                title="5 reasons MLops teams are using more edge ML"
                description="Lead ML Engineer at Encord Frederik Hvhilshoej shares his thoughts on deploying ML on the edge with VentureBeat."
                date="February 15, 2023"
                url="https://venturebeat.com/ai/5-reasons-why-mlops-teams-are-using-more-edge-ml/"
                author="Sharon Goldman"
                imageAlt="EE ews Europe Logo"
                authorURL="https://venturebeat.com/author/sharongoldman/"
              />
              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/306ee5dd-653f-40dd-b63a-6a70e93bb0e4_smart-tz.webp?auto=compress,format"
                }
                title="Open source active learning toolkit for computer vision"
                description="AI-assisted platform provider Encord has released a free open source industry agnostic toolkit designed to enable machine learning (ML) engineers and data scientists to understand and improve their training data quality and help boost model performance."
                date="January 26, 2023"
                url="https://www.eenewseurope.com/en/open-source-active-learning-toolkit-for-computer-vision/"
                author="Rich Pell"
                imageAlt=""
                authorURL="https://www.linkedin.com/in/financial-stock-contentwriter-grantwriter/"
              />
              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/cbea69ea-926f-4b78-9121-9504d4eef2ee_cw_logo.png?auto=compress,format"
                }
                title="Encord offers ML toolkit for computer vision apps"
                description="Encord Active is a free open source industry-agnostic toolkit that enables machine learning (ML) engineers and data scientists to understand and improve their training data quality."
                date="January 19, 2023"
                url="https://www.computerweekly.com/blog/Open-Source-Insider/Encord-offers-ML-toolkit-for-computer-vision-apps"
                author="Adrian Bridgwater"
                imageAlt="ComputerWeekly Logo"
                authorURL="https://www.techtarget.com/contributor/Adrian-Bridgwater"
              />
              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/b7ac2f7c-3de6-461f-954a-f97f8b4758ac_healthcare-digital-dark-logo.png?auto=compress,format"
                }
                title="How AI technology can help save nurses time & energy"
                description="Esben Thorius, Co-Founder at Teton.ai, and Eric Landau, CEO of Encord, discuss how AI technology can help to solve the nursing shortage in hospitals"
                date="January 19, 2023"
                url="https://healthcare-digital.com/technology-and-ai/how-ai-technology-can-help-save-nurses-time-energy"
                author="Helen Sydney Adams"
                imageAlt="Healthcare Digital Logo"
                authorURL="https://healthcare-digital.com/author/helen-sydney-adams"
              />
              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/264a0ff0-667c-44a3-ad93-05f3f1b0ed03_bn_logo.png?auto=compress,format"
                }
                title="How artificial intelligence is changing healthcare [Q&A]"
                description="BetaNews spoke to Eric Landau, founder and CEO of Encord, to find out more about the benefits and challenges of using AI in the healthcare sector."
                date="January 11, 2023"
                url="https://betanews.com/2023/01/11/how-artificial-intelligence-is-changing-healthcare-qa/"
                author="Ian Barker"
                imageAlt="BetaNews Logo"
                authorURL="https://betanews.com/author/ianbarker/"
              />
              <PressItem
                imageSrc={
                  "https://encord.cdn.prismic.io/encord/ad7a4068-5e03-4db0-a8cc-2849d34a9a88_image+%2810%29.svg"
                }
                title="AI will thrive in 3 key areas in 2023, despite economic conditions"
                description="Ulrik Stig Hansen provides his take on the key areas in which AI will thrive in 2023, despite the economic headwinds."
                date="December 18, 2022"
                url="https://venturebeat.com/ai/ai-will-thrive-in-3-areas-despite-economic-conditions-experts-say/"
                author="Ashleigh Hollowell"
                imageAlt="VentureBeat Logo"
                authorURL="https://venturebeat.com/author/ashleighhollowell/"
              />
              <PressItem
                imageSrc={
                  "https://encord.cdn.prismic.io/encord/c53761f3-edfe-4910-a8c3-b5e896910b20_HealthTech_Logo_Animated.svg"
                }
                title="Why are minority patients being left behind by AI?"
                description="Ulrik Stig Hansen and Eric Landau co-founders of Encord talks to Health Tech World how there’s an “urgent need” to answer questions around AI bias in healtcare."
                date="December 18, 2022"
                url="https://www.htworld.co.uk/news/ai/why-are-minority-patients-being-left-behind-by-ai/"
                author="Kirsty Rigg"
                imageAlt="HealthTech World Logo"
                authorURL="https://www.htworld.co.uk/author/kirstyrigg/"
              />
              <PressItem
                imageSrc={
                  "https://images.prismic.io/encord/ea40baff-6dac-4bb2-8a4e-a32db0d6b102_7c763928-9762-43a0-a55f-9ee9040fa6e1_210x210.jpg?auto=compress,format"
                }
                title="Guest post: Using One Methodology to Solve Three Model Failure Modes"
                description="In this TheSequence guest post, Eric Landau, CEO of Encord, discusses the three major model failure modes that prevent models from reaching the production state and shows how to solve all three problems with a single methodology."
                date="November 18, 2022"
                url="https://thesequence.substack.com/p/guest-post-using-one-methodology"
                author="Eric Landau"
                imageAlt="TheSequence Logo"
                authorURL="https://www.linkedin.com/in/eric-landau-40992ab0/"
              />

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.uktech.news/ai/ai-cluster-london-west-end-20221013"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        The AI cluster forming in London’s West End
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Thanks to a growing number of high-growth artificial
                    intelligence startups locating in the area, the West End may
                    soon be considered the UK’s latest AI cluster and a global
                    hub for the industry.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full" />
                          </span>
                          <img
                            className="relative rounded-full"
                            src={
                              "https://images.prismic.io/encord/2a3ec8cb-3873-4091-92b2-376580260a84_UKTN.png"
                            }
                            alt={"UKTN Logo"}
                            width={32}
                            loading="lazy"
                            height={32}
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.uktech.news/ai/ai-cluster-london-west-end-20221013"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          UKTN
                        </a>
                        <span className="text-gray-600">
                          {" "}
                          · October 13, 2022
                        </span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.uktech.news/ai/ai-cluster-london-west-end-20221013"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://hbr.org/2022/09/4-steps-to-start-monetizing-your-companys-data"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        4 Steps to Start Monetizing Your Company’s Data
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    As artificial intelligence becomes ubiquitous in business,
                    non-tech companies need to learn how to use their data to
                    gain a competitive edge. Companies that are trying to decide
                    where and how to use AI should take four steps.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full" />
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://encord.cdn.prismic.io/encord/deabc317-df50-48c9-bd93-87b45e9713e5_Harvard+Business+Review+Home.svg"}
                            alt={"Harvard Business Review Logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://hbr.org/2022/09/4-steps-to-start-monetizing-your-companys-data"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Harvard Business Review
                        </a>
                        <span className="text-gray-600">
                          {" "}
                          · September 27, 2022
                        </span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://hbr.org/2022/09/4-steps-to-start-monetizing-your-companys-data/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.htworld.co.uk/leadership/opinion/we-must-tackle-bias-in-ai-with-a-data-centric-approach/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Bias in AI is arguably one of biggest challenges for
                        computer vision in production
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Bias in AI is arguably the biggest challenge for innovators:
                    Ulrik Stig Hansen and Eric Landau co-founders of Encord,
                    share their insights with Health Tech World.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full" />
                          </span>
                          <img
                            className="relative rounded-full"
                            src={
                              "https://encord.cdn.prismic.io/encord/152356db-9013-40ca-9da7-c3da6d7b7770_Health+Tech+World.svg"
                            }
                            alt={"HealthTech logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.htworld.co.uk/leadership/opinion/we-must-tackle-bias-in-ai-with-a-data-centric-approach/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Health Tech World
                        </a>
                        <span className="text-gray-600">
                          {" "}
                          · September 26, 2022
                        </span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.htworld.co.uk/leadership/opinion/we-must-tackle-bias-in-ai-with-a-data-centric-approach/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.frost.com/frost-perspectives/top-artificial-intelligence-trends-influencing-the-future-of-radiology/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Frost & Sullivan on the impact of AI on radiology
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Frost & Sullivan took a closer look at how artificial
                    intelligence will impact radiology, and how companies like
                    Encord are playing a vital role in this growth.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full" />
                          </span>
                          <img
                            className="relative rounded-full"
                            src={
                              "https://images.prismic.io/encord/2a4bef08-6b12-4d43-b405-a3ebd826b0be_Frost-Sullivan-logo.jpg?auto=compress,format"
                            }
                            alt={"Frost & Sullivan"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.frost.com/frost-perspectives/top-artificial-intelligence-trends-influencing-the-future-of-radiology/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Frost & Sullivan
                        </a>
                        <span className="text-gray-600"> · July 28, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.businessinsider.com/meet-23-deeptech-startups-poised-to-defy-market-downturn-vcs-2022-7?r=US&IR=T"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.businessinsider.com/meet-23-deeptech-startups-poised-to-defy-market-downturn-vcs-2022-7?r=US&IR=T"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Here are 23 startups in areas like AI, robotics, and
                        quantum primed to take off in Europe's $711 billion deep
                        tech industry, according to VCs
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    We have been included in a group of 23 startups in areas
                    such as AI and quantum computing are attracting more capital
                    as VCs eye up founders that can drive next-generation
                    innovation.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full" />
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://images.prismic.io/encord/1738f502-6862-401b-8879-5007d0e407f5_Business-insider-logo.png?auto=compress,format"}
                            alt={"Business insider logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.businessinsider.com/meet-23-deeptech-startups-poised-to-defy-market-downturn-vcs-2022-7?r=US&IR=T"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BusinessInsider
                        </a>
                        <span className="text-gray-600"> · July 28, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.businessinsider.com/meet-23-deeptech-startups-poised-to-defy-market-downturn-vcs-2022-7?r=US&IR=T"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://insightscare.com/encord-stringing-a-powerful-cord-of-futuristic-data-centric-computer-vision/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Encord: Stringing a Powerful Cord of Futuristic
                        Data-Centric Computer Vision
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    How Encord strings a powerful cord between technology and
                    healthcare professionals by using futuristic data-centric
                    computer vision.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://images.prismic.io/encord/566d3294-3bfc-4d1d-91a6-33568dd066cf_InsightsCare-OP-Logo.png?auto=compress,format"}
                            alt={"Insights care logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://insightscare.com/encord-stringing-a-powerful-cord-of-futuristic-data-centric-computer-vision/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          InsightsCare
                        </a>
                        <span className="text-gray-600"> · July 25, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://insightscare.com/encord-stringing-a-powerful-cord-of-futuristic-data-centric-computer-vision/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.royalnavy.mod.uk/news-and-latest-activity/news/2022/june/17/220617-royal-navy-stormcloud"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Royal Navy works with tech giants to embrace innovation
                        at debut event
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Game-changing innovation enabled by Encord data labeling was
                    on show as the Royal Navy continues its commitment to
                    deliver the latest technology to the frontline of
                    operations.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://images.prismic.io/encord/bbe742ba-2b6e-4f9a-894e-1110b46e0daa_logo_royal_navy.webp?auto=compress,format"}
                            alt={"Royal Navy logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.royalnavy.mod.uk/news-and-latest-activity/news/2022/june/17/220617-royal-navy-stormcloud"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Royal Navy
                        </a>
                        <span className="text-gray-600"> · June 17, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.royalnavy.mod.uk/news-and-latest-activity/news/2022/june/17/220617-royal-navy-stormcloud"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://venturebeat.com/2022/06/01/encord-offers-tool-to-automatically-detect-errors-in-training-data/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Encord offers tool to automatically detect-errors in
                        training data
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Encord has developed the first-ever tool to help address the
                    growing problem of detecting human errors in data labeling.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://encord.cdn.prismic.io/encord/6c0ae7bd-f9ac-4b93-8c3a-b23b32886642_VentureBeat+Homepage.svg"}
                            alt={"VentureBeat logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://venturebeat.com/2022/06/01/encord-offers-tool-to-automatically-detect-errors-in-training-data/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          VentureBeat
                        </a>
                        <span className="text-gray-600"> · June 1, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://venturebeat.com/2022/06/01/encord-offers-tool-to-automatically-detect-errors-in-training-data/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://techcrunch.com/2022/05/27/4-questions-to-ask-before-building-a-computer-vision-model/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        4 questions to ask before building a computer vision
                        model
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Computer vision projects often fail. In this post, Eric
                    Landau discuss 4 important questions to ask before investing
                    heavily in building machine learning to solve business
                    problems.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://images.prismic.io/encord/0582dfeb-afeb-44ce-8d2b-9e422d619500_tc_logo.jpeg?auto=compress,format"}
                            alt={"TechCrunch logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://techcrunch.com/2022/05/27/4-questions-to-ask-before-building-a-computer-vision-model/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          TechCrunch
                        </a>
                        <span className="text-gray-600"> · May 27, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://techcrunch.com/2022/05/27/4-questions-to-ask-before-building-a-computer-vision-model/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.businesswire.com/news/home/20220517005775/en/Encord-Named-to-the-2022-CB-Insights-AI-100-List-of-Most-Innovative-Artificial-Intelligence-Startups"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Encord Named to the 2022 CB Insights AI 100 List of Most
                        Innovative Artificial Intelligence Startups
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    We were admitted to the prestigious list of companies CB
                    Insights has recognized as the most promising private
                    artificial intelligence companies. The companies on last
                    years list have raised $6 billion, including 20 mega-rounds
                    worth more than $100 million.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://encord.cdn.prismic.io/encord/351c0e67-487c-405a-928b-f21d7a94c748_Business+Wire+Logo+213x87.svg"}
                            alt={"Business Wire logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.businesswire.com/news/home/20220517005775/en/Encord-Named-to-the-2022-CB-Insights-AI-100-List-of-Most-Innovative-Artificial-Intelligence-Startups"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BusinessWire
                        </a>
                        <span className="text-gray-600"> · May 17, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.businesswire.com/news/home/20220517005775/en/Encord-Named-to-the-2022-CB-Insights-AI-100-List-of-Most-Innovative-Artificial-Intelligence-Startups"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.verdict.co.uk/cto-talk-eric-landau-of-encord/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CTO Talk: Eric Landau of Encord
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    What do people get wrong about AI and what are the biggest
                    mistakes people make when it comes to big data?
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://images.prismic.io/encord/d10c0bf3-ffd8-445f-91c3-12e1177dfdd1_Verdict-logo.png?auto=compress,format"}
                            alt={"Verdict logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.verdict.co.uk/cto-talk-eric-landau-of-encord/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Verdict
                        </a>
                        <span className="text-gray-600"> · May 11, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.verdict.co.uk/cto-talk-eric-landau-of-encord/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.maddyness.com/uk/2022/05/11/stop-being-precious-about-building-data-infrastructure-tools/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Stop being precious about building data infrastructure
                        tools
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Most startup founders will agree that having a first-mover's
                    advantage by reducing time to market is an essential part of
                    their long-term success strategy.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://encord.cdn.prismic.io/encord/d2d6a8af-4dab-4aa1-859d-b8027eab8124_svgexport-2.svg"}
                            alt={"Maddyness logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.maddyness.com/uk/2022/05/11/stop-being-precious-about-building-data-infrastructure-tools/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Maddyness
                        </a>
                        <span className="text-gray-600"> · May 11, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.maddyness.com/uk/2022/05/11/stop-being-precious-about-building-data-infrastructure-tools/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://techcrunch.com/2022/04/08/a-newcomer-to-ai-data-labeling-encord-looks-to-ride-a-rising-tidal-wave/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Encord launches DICOM tool, providing 3D annotation for
                        modalities such as CT, X-ray, and MRI.
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    The first truly native DICOM tool for machine learning
                    annotation with full pixel intensity rendering capabilities
                    - 20,000+ pixels compared to just 256 in a regular browser
                    canvas, windows defined by Hounsfield units, coronal, axial,
                    and sagittal views - and much more.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://images.prismic.io/encord/0582dfeb-afeb-44ce-8d2b-9e422d619500_tc_logo.jpeg?auto=compress,format"}
                            alt={"TechCrunch logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://techcrunch.com/2022/04/08/a-newcomer-to-ai-data-labeling-encord-looks-to-ride-a-rising-tidal-wave/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          TechCrunch
                        </a>
                        <span className="text-gray-600"> · April 8, 2022</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://techcrunch.com/2022/04/08/a-newcomer-to-ai-data-labeling-encord-looks-to-ride-a-rising-tidal-wave/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.businesswire.com/news/home/20211013005084/en/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Encord raises $12.5M in fresh Series A funding
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Encord continues record growth with its new micro-model
                    approach, automating an archaic annotation process with
                    $12.5M in new funding.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://encord.cdn.prismic.io/encord/351c0e67-487c-405a-928b-f21d7a94c748_Business+Wire+Logo+213x87.svg"}
                            alt={"Business Wire logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.businesswire.com/news/home/20211013005084/en/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BusinessWire
                        </a>
                        <span className="text-gray-600">
                          {" "}
                          · October 13, 2021
                        </span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.businesswire.com/news/home/20211013005084/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://venturebeat.com/2021/06/15/cord-raises-4-4m-to-automate-computer-vision-annotation-processes/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Encord raises $4.5M to automate computer vision
                        annotation processes
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Encord, a startup automating annotation processes for
                    computer vision, today announced that it raised $4.5 million
                    in a seed round led by CRV.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://encord.cdn.prismic.io/encord/6c0ae7bd-f9ac-4b93-8c3a-b23b32886642_VentureBeat+Homepage.svg"}
                            alt={"VentureBeat logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://venturebeat.com/2021/06/15/cord-raises-4-4m-to-automate-computer-vision-annotation-processes/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          VentureBeat
                        </a>
                        <span className="text-gray-600"> · June 15, 2021</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://venturebeat.com/2021/06/15/cord-raises-4-4m-to-automate-computer-vision-annotation-processes/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://borsen.dk/nyheder/virksomheder/dansk-ivaerksaetter-henter-millioner-i-silicon-valley/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Entrepreneurs raises millions from Silicon Valley
                        venture fund CRV (in Danish)
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Iværksættere stiftede startup over en øl på den lokale pub –
                    et år senere får de millioner fra Silicon Valley-venturefond
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://images.prismic.io/encord/758e549c-ea5c-47bc-973f-a8cb5098d4c1_borsen-logo.png?auto=compress,format"}
                            alt={"Borsen logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://borsen.dk/nyheder/virksomheder/dansk-ivaerksaetter-henter-millioner-i-silicon-valley/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Borsen
                        </a>
                        <span className="text-gray-600"> · June 09, 2021</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://borsen.dk/nyheder/virksomheder/dansk-ivaerksaetter-henter-millioner-i-silicon-valley/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8046591/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        AI everywhere in endoscopy, not only for detection and
                        characterization</a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Developing an AI model took an average of 1 year, the new
                    annotation system compressed that time to 2 to 3 months.
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://encord.cdn.prismic.io/encord/5ff45cac-0fcf-4f17-bd7a-2cbce75f66f4_thieme-favicon.svg"}
                            alt={"Thieme logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.thieme-connect.com/products/ejournals/abstract/10.1055/a-1452-9242?device=desktop&innerWidth=412&offsetWidth=412"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Endoscopy International Open
                        </a>
                        <span className="text-gray-600"> · April 14, 2021</span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8046591/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>

              <article className="flex items-center py-4 border-b border-gray-200">
                <div>
                  <header>
                    <h2 className="mb-2 h4">
                      <a
                        href="https://www.computerworld.dk/art/254891/30-aarig-dansker-bliver-del-af-verdensberoemt-ivaerksaetter-program-startup-skal-revolutionere-kunstig-intelligens/"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Encord is accepted into Y Combinator (in Danish)
                      </a>
                    </h2>
                  </header>
                  <div className="mb-4 text-lg text-gray-600">
                    Dansker bliver del af verdensberømt iværksætter-program:
                    Startup skal revolutionere kunstig intelligens
                  </div>
                  <footer className="text-sm">
                    <div className="flex items-center">
                      <div className="flex flex-shrink-0 mr-3">
                        <div className="relative" href="#0">
                          <span
                            className="absolute inset-0 -m-px"
                            aria-hidden="true"
                          >
                            <span className="absolute inset-0 -m-px bg-white rounded-full"></span>
                          </span>
                          <img
                            className="relative rounded-full"
                            src={"https://images.prismic.io/encord/6a56bd35-9189-44c7-ad9b-f832118d479f_computer_world_logo.jpeg?auto=compress,format"}
                            alt={"Computer World logo"}
                            width={32}
                            height={32}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.computerworld.dk/art/254891/30-aarig-dansker-bliver-del-af-verdensberoemt-ivaerksaetter-program-startup-skal-revolutionere-kunstig-intelligens/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ComputerWorld
                        </a>
                        <span className="text-gray-600">
                          {" "}
                          · January 11, 2021
                        </span>
                      </div>
                    </div>
                  </footer>
                </div>
                <a
                  href="https://www.computerworld.dk/art/254891/30-aarig-dansker-bliver-del-af-verdensberoemt-ivaerksaetter-program-startup-skal-revolutionere-kunstig-intelligens/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-shrink-0 block ml-6"
                >
                  <span className="sr-only">Read more</span>
                  <svg
                    className="w-4 h-4 text-blue-600 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </a>
              </article>
            </div>

            {/* Sidebar */}
            <aside className="relative mt-12 md:mt-0 md:w-64 md:ml-12 lg:ml-20 md:flex-shrink-0">
              {/* Popular posts */}
              <div className="mb-8">
                <h4 className="mb-4 text-lg font-bold leading-snug tracking-tight">
                  Popular
                </h4>
                <ul className="-my-2">
                  <li className="flex py-2 border-b border-gray-200">
                    <svg
                      className="flex-shrink-0 w-4 h-4 mt-1 mr-3 text-gray-400 fill-current"
                      viewBox="0 0 16 16"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path d="M15.686 5.695L10.291.3c-.4-.4-.999-.4-1.399 0s-.4.999 0 1.399l.6.599-6.794 3.697-1-1c-.4-.399-.999-.399-1.398 0-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 13.988 2 15.387l3.696-3.697 3.997 3.996c.5.5 1.199.2 1.398 0 .4-.4.4-.999 0-1.398l-.999-1 3.697-6.694.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499zM8.493 11.79L4.196 7.494l6.695-3.697 1.298 1.299-3.696 6.694z" />
                    </svg>
                    <article>
                      <h3 className="mb-1 font-medium">
                        <a
                          href="https://www.businesswire.com/news/home/20211013005084/en/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline"
                        >
                          Encord raises $12.5M in fresh Series A funding
                        </a>
                      </h3>
                      <div className="text-sm text-gray-800">
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.businesswire.com/news/home/20211013005084/en/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BusinessWire
                        </a>
                      </div>
                    </article>
                  </li>

                  <li className="flex py-2 border-b border-gray-200">
                    <svg
                      className="flex-shrink-0 w-4 h-4 mt-1 mr-3 text-gray-400 fill-current"
                      viewBox="0 0 16 16"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path d="M15.686 5.695L10.291.3c-.4-.4-.999-.4-1.399 0s-.4.999 0 1.399l.6.599-6.794 3.697-1-1c-.4-.399-.999-.399-1.398 0-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 13.988 2 15.387l3.696-3.697 3.997 3.996c.5.5 1.199.2 1.398 0 .4-.4.4-.999 0-1.398l-.999-1 3.697-6.694.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499zM8.493 11.79L4.196 7.494l6.695-3.697 1.298 1.299-3.696 6.694z" />
                    </svg>
                    <article>
                      <h3 className="mb-1 font-medium">
                        <a
                          href="https://venturebeat.com/2021/06/15/cord-raises-4-4m-to-automate-computer-vision-annotation-processes/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline"
                        >
                          Encord raises $4.5M to automate computer vision
                          annotation processes
                        </a>
                      </h3>
                      <div className="text-sm text-gray-800">
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://venturebeat.com/2021/06/15/cord-raises-4-4m-to-automate-computer-vision-annotation-processes/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          VentureBeat
                        </a>
                      </div>
                    </article>
                  </li>
                  <li className="flex py-2 border-b border-gray-200">
                    <svg
                      className="flex-shrink-0 w-4 h-4 mt-1 mr-3 text-gray-400 fill-current"
                      viewBox="0 0 16 16"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path d="M15.686 5.695L10.291.3c-.4-.4-.999-.4-1.399 0s-.4.999 0 1.399l.6.599-6.794 3.697-1-1c-.4-.399-.999-.399-1.398 0-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 13.988 2 15.387l3.696-3.697 3.997 3.996c.5.5 1.199.2 1.398 0 .4-.4.4-.999 0-1.398l-.999-1 3.697-6.694.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499zM8.493 11.79L4.196 7.494l6.695-3.697 1.298 1.299-3.696 6.694z" />
                    </svg>
                    <article>
                      <h3 className="mb-1 font-medium">
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8046591/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline"
                        >
                          AI everywhere in endoscopy, not only for detection and
                          characterization
                        </a>
                      </h3>
                      <div className="text-sm text-gray-800">
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.thieme-connect.com/products/ejournals/abstract/10.1055/a-1452-9242?device=desktop&innerWidth=412&offsetWidth=412"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Endoscopy International Open
                        </a>
                      </div>
                    </article>
                  </li>
                  <li className="flex py-2 border-b border-gray-200">
                    <svg
                      className="flex-shrink-0 w-4 h-4 mt-1 mr-3 text-gray-400 fill-current"
                      viewBox="0 0 16 16"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path d="M15.686 5.695L10.291.3c-.4-.4-.999-.4-1.399 0s-.4.999 0 1.399l.6.599-6.794 3.697-1-1c-.4-.399-.999-.399-1.398 0-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 13.988 2 15.387l3.696-3.697 3.997 3.996c.5.5 1.199.2 1.398 0 .4-.4.4-.999 0-1.398l-.999-1 3.697-6.694.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499zM8.493 11.79L4.196 7.494l6.695-3.697 1.298 1.299-3.696 6.694z" />
                    </svg>
                    <article>
                      <h3 className="mb-1 font-medium">
                        <a
                          href="https://www.computerworld.dk/art/254891/30-aarig-dansker-bliver-del-af-verdensberoemt-ivaerksaetter-program-startup-skal-revolutionere-kunstig-intelligens/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline"
                        >
                          Encord is accepted into Y Combinator
                        </a>
                      </h3>
                      <div className="text-sm text-gray-800">
                        <span className="text-gray-600">By </span>
                        <a
                          className="font-medium hover:underline"
                          href="https://www.computerworld.dk/art/254891/30-aarig-dansker-bliver-del-af-verdensberoemt-ivaerksaetter-program-startup-skal-revolutionere-kunstig-intelligens/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ComputerWorld
                        </a>
                      </div>
                    </article>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PressList;
