import React, { useState } from "react";
import Modal from "./Modal";
import axios from "axios";

const ContactModal = ({ modalID, modalTitle, contactModalOpen, setContactModalOpen }) => {
  const [email, setEmail] = useState("");
  const [interest] = useState("");
  const [message, setMessage] = useState("");
  const [, setSendingMessage] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(false);

  const submitContactDetails = (e) => {
    const formValid = document.getElementById("contact_form").checkValidity();
    if (formValid) {
      e.preventDefault();
      setSendingMessage(true);
      const data = packageContactInformation();
      axios
        .post("https://api.cord.tech/api_integration/slack", data)
        .then((r) => {
          if (r.data.response) {
            setSendingMessage(false);
            setContactSuccess(true);
          } else {
            setSendingMessage(false);
          }
        });
    }
  };

  const packageContactInformation = () => {
    return {
      query_type: "send_message_to_sales",
      email,
      inquiryType: interest,
      optionalPhone: "",
      optionalMessage: message,
    };
  };

  return (
    <Modal
      id={modalID}
      ariaLabel="modal-headline"
      show={contactModalOpen}
      handleClose={() => setContactModalOpen(false)}
    >
      {contactSuccess ? (
        <>
          <div className="pl-2 pb-4 pt-2">
            <p className="text-3xl font-black">Message sent</p>
          </div>
          <p className="text-lg text-gray-600 mb-8 pl-2">
            Thanks for reaching out to us! A member of our team will get in
            touch with you shortly. You may now close this window.
          </p>
          <div className="flex justify-end items-end pt-20 pb-4 pr-2">
            <button
              className="btn px-0 text-white bg-blue-600 hover:bg-blue-700 w-32"
              onClick={() => setContactModalOpen(false)}
            >
              Close window
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="pl-2 pb-4 pt-2">
            <p className="text-3xl font-black">{modalTitle}</p>
          </div>
          <form id="contact_form">
            <fieldset>
              <div className="w-full px-3 pb-4">
                <label
                  className="block text-gray-800 text-sm font-medium mb-1"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  className="form-input w-full text-gray-800"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              {/*<div className="w-full px-3 pb-4">*/}
              {/*    <label*/}
              {/*        className="block text-gray-800 text-sm font-medium mb-1"*/}
              {/*        htmlFor="interest"*/}
              {/*    >*/}
              {/*        Number of expected users*/}
              {/*    </label>*/}
              {/*    <select*/}
              {/*        id="interest"*/}
              {/*        className="form-select w-full text-gray-800"*/}
              {/*        value={interest}*/}
              {/*        onChange={(e) => setInterest(e.target.value)}*/}
              {/*    >*/}
              {/*        <option hidden>Enter number of users (optional)</option>*/}
              {/*        <option>1-3</option>*/}
              {/*        <option>4-10</option>*/}
              {/*        <option>11-20</option>*/}
              {/*        <option>21-50</option>*/}
              {/*        <option>More than 50</option>*/}
              {/*    </select>*/}
              {/*</div>*/}
              <div className="w-full px-3 pb-8">
                <label
                  className="block text-gray-800 text-sm font-medium mb-1"
                  htmlFor="message"
                >
                  Describe your use case
                </label>
                <textarea
                  id="message"
                  className="form-textarea w-full text-gray-800"
                  rows="3"
                  placeholder="Enter your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>

              <div className="flex justify-end items-end pb-4 pr-2">
                <button
                  className="btn px-0 text-white bg-blue-600 hover:bg-blue-700 w-32"
                  onClick={submitContactDetails}
                >
                  Submit
                </button>
                &nbsp; &nbsp;
                <button
                  className="btn px-0 text-gray-700 bg-gray-200 hover:bg-gray-300 w-32"
                  onClick={() => setContactModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </fieldset>
          </form>
        </>
      )}
    </Modal>
  );
}

export default ContactModal;
