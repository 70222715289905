import React, { useState } from "react";

import PressList from "../partials/PressList";
import ContactModal from "../utilities/ContactModal";
import LeftColours from "../utilities/LeftColours";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Press = ({ location }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false);
  return (
    <Layout location={location}>
      <LeftColours />

      {/*  Page sections */}
      <PressList />

      {/* Modal */}
      <ContactModal
        modalID="fpModal"
        modalTitle="Talk to us"
        contactModalOpen={contactModalOpen}
        setContactModalOpen={setContactModalOpen}
      />
    </Layout>
  );
};

export default Press;

export const Head = () => (
  <SEO
    title="Encord in the press"
    description="Take a look at the latest mentions of Encord around the internet and in the press."
  />
);
